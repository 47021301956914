import { IsEnum, IsNotEmpty, IsString, IsInstance, IsBoolean, IsInt, Min, Max } from 'class-validator';
import { Dto } from '@domain/models/Dto';
import { UnixTimestamp } from '@domain/types';
import { ExperimentType } from '@domain/enums/ExperimentType';
import { ExperimentPeriodicity } from '@domain/enums/ExperimentPeriodicity';
import { NewExperimentObjectiveDto } from '@domain/models/experiment/NewExperimentObjectiveDto';

export class NewExperimentDto extends Dto {
  @IsString()
  @IsNotEmpty()
  public experimentName: string;
  public startDateTime: UnixTimestamp;

  @IsEnum(ExperimentPeriodicity)
  public checkPeriod: ExperimentPeriodicity;

  @IsEnum(ExperimentType)
  @IsNotEmpty()
  public type: ExperimentType;

  @IsString()
  public queryTemplate: string;

  @IsInt()
  @Min(1)
  @Max(31)
  public arpu: number;

  // todo [CU-1152] update/drop, currently hardcoded on [BE]
  public kpi: { name: string; available: boolean }; // TODO: Required only for AB_TESTING

  @IsBoolean()
  public automaticMode: boolean;

  @IsInt({ each: true })
  public reportingArpu: number[];

  public deadline: UnixTimestamp;

  @IsString({ each: true })
  public appVersions: string[];

  public minInstallDate: UnixTimestamp;

  @IsInt()
  @Min(0)
  public ageMin: number;

  @IsInt()
  @Min(0)
  public ageMax: number;

  @IsBoolean()
  public enableOtherWorld: boolean;

  @IsInt()
  @IsNotEmpty()
  public gameId: number;

  @IsInstance(NewExperimentObjectiveDto)
  @IsNotEmpty()
  public experimentObjectives: NewExperimentObjectiveDto[];

  @IsBoolean()
  public recommendedProfile: boolean;

  @IsString()
  public importedSegments: string;
}
