import React, { useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import cn from 'classnames';

import { useToggle } from '@ui/hooks/useToggle';
import {
  ObjectiveConfigParams,
  ControlGroupSessionForm,
  InputGroupSessionForm
} from '@domain/models/createExperiment/ObjectiveConfigParams';
import { NextButton } from '@pages/createExperiment/atoms/NextButton/NextButton';
import { AdProfileParamsInput } from '@pages/createExperiment/objectiveConfig/forms/AB/inputs/AdProfileParamsInput';
import { CloneInput } from '@pages/createExperiment/objectiveConfig/forms/AB/inputs/CloneInput';
import { NewUsersInput } from '@pages/createExperiment/objectiveConfig/forms/AB/inputs/NewUsersInput';
import { StickyInput } from '@pages/createExperiment/objectiveConfig/forms/AB/inputs/StickyInput';
import { AdjustableUsersInput } from '@pages/createExperiment/objectiveConfig/forms/AB/inputs/AdjustablePersentUsersInput';
import { PercentUsersInput } from '@pages/createExperiment/objectiveConfig/forms/AB/inputs/PercentUsersInput';
import { BuiltInConfigInput } from '@pages/createExperiment/objectiveConfig/forms/AB/inputs/BuiltInConfigInput';
import { VariableList } from '@pages/createExperiment/objectiveConfig/forms/AB/components/variableList/VariableList';
import { ControlGroup } from '@pages/createExperiment/objectiveConfig/forms/AB/components/controlGroup/ControlGroup';
import { InputGroup } from '@pages/createExperiment/objectiveConfig/forms/AB/components/inputGroup/InputGroup';
import { FormComponent } from '@ui/hooks/form';
import { fetchGameInstallsStats } from '@infrastructure/store/createExperiment/createExperimentActions';
import { useDispatch, useSelector } from 'react-redux';
import { UserAllocationFormBlock } from '@pages/createExperiment/objectiveConfig/atoms/UserAllocationFormBlock';

import styles from '@pages/createExperiment/objectiveConfig/forms/AB/ABForm.module.scss';
import { createExperimentSelectors } from '@infrastructure/store/createExperiment/createExperimentSelectors';
import { appPropertiesSelectors } from '@infrastructure/store/applicationProperties/appPropertiesSelectors';
import { CreateExperimentForm } from '@domain/enums/CreateExperimentForm';

type Props = FormComponent<ObjectiveConfigParams> & {
  region: string;
  defaultControlGroup: ControlGroupSessionForm;
  defaultInputGroup: InputGroupSessionForm;
  isExtended: boolean;
  isPrevSubmitted: boolean;
};

export function ObjectiveConfig({
  register,
  control,
  handleSubmit,
  region,
  defaultControlGroup,
  defaultInputGroup,
  isExtended,
  isPrevSubmitted
}: Props) {
  const dispatch = useDispatch();

  const regionData = useSelector(createExperimentSelectors.getRegionByName(region));
  const gameInstalls = useSelector(createExperimentSelectors.getGameInstalls)[region];
  const form = useSelector(createExperimentSelectors.getForm);
  const minUsersPerGroup = useSelector(appPropertiesSelectors.getMinUsersPerGroup);

  const { isActive, handleOpen } = useToggle(false);

  useEffect(() => {
    if (isExtended || isPrevSubmitted) {
      handleOpen();
    }
  }, [isExtended, isPrevSubmitted, handleOpen]);

  useEffect(() => {
    if (!gameInstalls && regionData) {
      dispatch(fetchGameInstallsStats.trigger(regionData));
    }
  }, [dispatch, regionData, gameInstalls]);

  const params = useWatch({
    control,
    name: 'params'
  });

  if (!params) {
    return null;
  }

  const paramsExist = params.length > 0;

  const { importedSegments } = form[CreateExperimentForm.TARGET_CONFIG];
  const isImportedSegments = Boolean(importedSegments);

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <button type="button" className={cn(styles.title, !isActive && styles.highlight)} onClick={handleOpen}>
        Region: {region}
      </button>

      {isActive && (
        <>
          <UserAllocationFormBlock gameInstalls={gameInstalls} minUsersPerGroup={minUsersPerGroup} />
          <fieldset className={styles.fieldset}>
            <AdProfileParamsInput register={register} control={control} />
            <CloneInput register={register} />
            <BuiltInConfigInput register={register} />
          </fieldset>
          {paramsExist && (
            <>
              <VariableList control={control} />
              <ControlGroup control={control} defaultControlGroup={defaultControlGroup} />
              <InputGroup control={control} register={register} defaultInputGroup={defaultInputGroup} />
              <PercentUsersInput control={control} />
              <AdjustableUsersInput register={register} />
              <NewUsersInput register={register} disabled={isImportedSegments} />
              <StickyInput control={control} />
            </>
          )}

          <NextButton />
        </>
      )}
    </form>
  );
}
