import { IsArray, ValidateNested, IsNotEmpty } from 'class-validator';
import { Type } from 'class-transformer';
import { VersionInput } from '@domain/models/VersionInput';

export class RegionOption {
  value: string;
  label: string;
}

export class DevTargetingParams {
  @IsNotEmpty()
  public primaryRegion: string;

  // regions validated separately in the DevPhaseForm.tsx component
  regions: RegionOption[];
  regionsForInDev: RegionOption[];

  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => VersionInput)
  appVersions: VersionInput[];

  static ofInitial() {
    const params = new DevTargetingParams();

    params.regions = [];
    params.regionsForInDev = [];
    params.primaryRegion = '';
    params.appVersions = [{ value: '' }];

    return params;
  }
}
