import React, { useEffect } from 'react';
import { plainToInstance } from 'class-transformer';

import { ExperimentFormMapper } from '@app/mappers/experiment/ExperimentFormMapper';
import { pushABObjectiveForm } from '@infrastructure/store/createExperiment/createExperimentActions';
import { configSelectors } from '@infrastructure/store/config/configSelectors';
import { ObjectiveConfigParams } from '@domain/models/createExperiment/ObjectiveConfigParams';
import { ControlGroupByCountry } from '@domain/models/ControlGroup';
import { ObjectiveConfig } from '@pages/createExperiment/objectiveConfig/forms/AB/ObjectiveConfig';
import { useDispatch, useSelector } from '@ui/hooks/redux';
import { useForm } from '@ui/hooks/form';
import { createExperimentSelectors } from '@infrastructure/store/createExperiment/createExperimentSelectors';

type Props = {
  regionName: string;
  controlGroups: ControlGroupByCountry;
  prevRegion: string;
  index: number;
};

export function ABForm({ regionName, prevRegion, controlGroups, index }: Props) {
  const dispatch = useDispatch();

  const configList = useSelector(createExperimentSelectors.getABConfigParamsList);
  const variables = useSelector(configSelectors.getVariableList);
  const isSubmitted = useSelector(createExperimentSelectors.getIsABConfigSubmitted(regionName));
  const { objectiveABConfig, targetConfig } = useSelector(createExperimentSelectors.getForm);
  const isClone = useSelector(createExperimentSelectors.isClone);
  const isPrevSubmitted = useSelector(createExperimentSelectors.getIsABConfigSubmitted(prevRegion));
  const regionDto = useSelector(createExperimentSelectors.getRegionByName(regionName));

  const controlGroup = regionDto
    ? controlGroups[regionDto.countryCodes[0]] || controlGroups.Default
    : controlGroups.Default;

  const defaultControlGroups = ExperimentFormMapper.mapDefaultControlGroups(variables, controlGroup);
  const defaultInputGroups = ExperimentFormMapper.mapDefaultInputGroups(variables, controlGroup);

  let defaultValues;

  if (isClone && objectiveABConfig[regionName]) {
    const configParams = objectiveABConfig[regionName];
    defaultValues = configParams.mergeControlGroup(defaultControlGroups[0]).mergeInputGroup(defaultInputGroups[0]);
  } else {
    defaultValues = ObjectiveConfigParams.ofInitial()
      .setControlGroup(defaultControlGroups)
      .setInputGroup(defaultInputGroups)
      .setNewUsersByImportedSegments(targetConfig.importedSegments);
  }

  const configByRegion = configList[regionName];
  const isExtended = isSubmitted || index === 0;

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue
  } = useForm<ObjectiveConfigParams>({
    schema: ObjectiveConfigParams,
    defaultValues
  });

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'newUsers' && type === 'change') {
        setValue('sticky', Boolean(value.newUsers));
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  useEffect(() => {
    if (isSubmitted) {
      reset(configByRegion);
    }
  }, [isSubmitted, configByRegion, reset]);

  const handleNext = handleSubmit((body) => {
    const form = plainToInstance(ObjectiveConfigParams, body);

    dispatch(pushABObjectiveForm({ region: regionName, form }));
  });

  return (
    <ObjectiveConfig
      errors={errors}
      register={register}
      control={control}
      handleSubmit={handleNext}
      region={regionName}
      defaultControlGroup={defaultControlGroups[0]}
      defaultInputGroup={defaultInputGroups[0]}
      isExtended={isExtended}
      isPrevSubmitted={isPrevSubmitted}
    />
  );
}
