import React from 'react';
import { CheckboxInput } from 'crazy-ui-next';
import { useController, useWatch } from 'react-hook-form';

import { ObjectiveConfigParams } from '@domain/models/createExperiment/ObjectiveConfigParams';
import { FormComponent } from '@ui/hooks/form';

import styles from '@pages/createExperiment/objectiveConfig/forms/AB/ABForm.module.scss';

type Props = Pick<FormComponent<ObjectiveConfigParams>, 'control'>;

export function StickyInput({ control }: Props) {
  const { field } = useController({ control, name: 'sticky' });
  const newUsers = useWatch({ control, name: 'newUsers' });

  if (newUsers) {
    return null;
  }

  return (
    <CheckboxInput
      labelText="Sticky"
      className={styles.input}
      checked={field.value}
      onChange={field.onChange}
      name={field.name}
    />
  );
}
